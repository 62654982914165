<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'StatusIcon',
})
</script>
<template>
  <div
    class="status-icon-container"
    :style="{
      color: colorType[type],
      minWidth: '112px',
      minHeight: '112px',
    }"
  >
    <svg
      version="1.1"
      id="图层_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 91 91"
      :style="{
        enableBackground: 'new 0 0 91 91',
        width: '100%',
      }"
      xml:space="preserve"
    >
      <g transform="translate(-1709.0 -680.0)">
        <g>
          <defs>
            <rect id="SVGID_1_" width="2187" height="1074" />
          </defs>
          <clipPath id="SVGID_00000066495973899708844240000000849445066480707998_">
            <use xlink:href="#SVGID_1_" style="overflow: visible" />
          </clipPath>
          <g style="clip-path: url(#SVGID_00000066495973899708844240000000849445066480707998_)">
            <g transform="translate(1709.0 680.0)">
              <g>
                <defs>
                  <circle
                    id="SVGID_00000035515577015339336010000003761039743785859260_"
                    cx="45.5"
                    cy="45.5"
                    r="45.5"
                  />
                </defs>
                <clipPath id="SVGID_00000164511710966146053860000000912895288686462860_">
                  <use
                    xlink:href="#SVGID_00000035515577015339336010000003761039743785859260_"
                    style="overflow: visible"
                  />
                </clipPath>
                <g
                  style="clip-path: url(#SVGID_00000164511710966146053860000000912895288686462860_)"
                >
                  <path
                    class="st2"
                    d="M45.5,91C70.6,91,91,70.6,91,45.5S70.6,0,45.5,0S0,20.4,0,45.5S20.4,91,45.5,91z"
                  />
                </g>
              </g>
              <g transform="translate(4.0 4.0)">
                <g>
                  <defs>
                    <circle
                      id="SVGID_00000062189980684399014190000016190528536475741079_"
                      cx="41.5"
                      cy="41.5"
                      r="41.5"
                    />
                  </defs>
                  <clipPath id="SVGID_00000174572695468876343230000016267406061555618485_">
                    <use
                      xlink:href="#SVGID_00000062189980684399014190000016190528536475741079_"
                      style="overflow: visible"
                    />
                  </clipPath>
                  <g
                    style="
                      clip-path: url(#SVGID_00000174572695468876343230000016267406061555618485_);
                    "
                  >
                    <path
                      class="st4"
                      d="M41.5,83C64.4,83,83,64.4,83,41.5S64.4,0,41.5,0S0,18.6,0,41.5S18.6,83,41.5,83z"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(22.734131055614853 20.0)">
                <path
                  class="st5"
                  d="M45,38c-4.4,7.8-12.7,13-22.2,13S4.9,45.8,0.5,38 M0,14C4.2,5.7,12.8,0,22.8,0s18.6,5.7,22.8,14"
                />
              </g>
              <g transform="translate(42.774108351683026 11.774108351682571)">
                <g>
                  <defs>
                    <polygon
                      id="SVGID_00000127024388401098998230000005656003646117788341_"
                      points="3,0 3.9,1.8 5.9,2.1 4.4,3.5 4.8,5.4 
								3,4.5 1.2,5.4 1.6,3.5 0.1,2.1 2.1,1.8 							"
                    />
                  </defs>
                  <clipPath id="SVGID_00000005975220712955944640000006009148125243997577_">
                    <use
                      xlink:href="#SVGID_00000127024388401098998230000005656003646117788341_"
                      style="overflow: visible"
                    />
                  </clipPath>
                  <g
                    style="
                      clip-path: url(#SVGID_00000005975220712955944640000006009148125243997577_);
                    "
                  >
                    <polygon class="st7" points="0.1,0 5.9,0 5.9,5.4 0.1,5.4 							" />
                  </g>
                </g>
              </g>
              <g transform="translate(32.774108351683026 14.774108351682571)">
                <g>
                  <defs>
                    <polygon
                      id="SVGID_00000150067443797934748670000006040528855682492837_"
                      points="2,0 2.6,1.2 3.9,1.4 3,2.3 3.2,3.6 2,3 
								0.8,3.6 1,2.3 0.1,1.4 1.4,1.2 							"
                    />
                  </defs>
                  <clipPath id="SVGID_00000106128757872719393270000012289338342173310636_">
                    <use
                      xlink:href="#SVGID_00000150067443797934748670000006040528855682492837_"
                      style="overflow: visible"
                    />
                  </clipPath>
                  <g
                    style="
                      clip-path: url(#SVGID_00000106128757872719393270000012289338342173310636_);
                    "
                  >
                    <polygon class="st7" points="0.1,0 3.9,0 3.9,3.6 0.1,3.6 							" />
                  </g>
                </g>
              </g>
              <g transform="translate(54.774108351683026 14.774108351682571)">
                <g>
                  <defs>
                    <polygon
                      id="SVGID_00000107548315041627561430000013841378850263050889_"
                      points="2,0 2.6,1.2 3.9,1.4 3,2.3 3.2,3.6 2,3 
								0.8,3.6 1,2.3 0.1,1.4 1.4,1.2 							"
                    />
                  </defs>
                  <clipPath id="SVGID_00000000912247332508321870000010320302003427221182_">
                    <use
                      xlink:href="#SVGID_00000107548315041627561430000013841378850263050889_"
                      style="overflow: visible"
                    />
                  </clipPath>
                  <g
                    style="
                      clip-path: url(#SVGID_00000000912247332508321870000010320302003427221182_);
                    "
                  >
                    <polygon class="st7" points="0.1,0 3.9,0 3.9,3.6 0.1,3.6 							" />
                  </g>
                </g>
              </g>
              <g transform="translate(42.774108351683026 78.77410835168257) scale(1.0 -1.0)">
                <g>
                  <defs>
                    <polygon
                      id="SVGID_00000168103624506885388410000014407601758635500678_"
                      points="3,0 3.9,1.8 5.9,2.1 4.4,3.5 4.8,5.4 
								3,4.5 1.2,5.4 1.6,3.5 0.1,2.1 2.1,1.8 							"
                    />
                  </defs>
                  <clipPath id="SVGID_00000119804035723197245160000005865337457184985754_">
                    <use
                      xlink:href="#SVGID_00000168103624506885388410000014407601758635500678_"
                      style="overflow: visible"
                    />
                  </clipPath>
                  <g
                    style="
                      clip-path: url(#SVGID_00000119804035723197245160000005865337457184985754_);
                    "
                  >
                    <polygon class="st7" points="0.1,0 5.9,0 5.9,5.4 0.1,5.4 							" />
                  </g>
                </g>
              </g>
              <g transform="translate(32.774108351683026 75.77410835168257) scale(1.0 -1.0)">
                <g>
                  <defs>
                    <polygon
                      id="SVGID_00000024698157503546209570000003709317895558988948_"
                      points="2,0 2.6,1.2 3.9,1.4 3,2.3 3.2,3.6 2,3 
								0.8,3.6 1,2.3 0.1,1.4 1.4,1.2 							"
                    />
                  </defs>
                  <clipPath id="SVGID_00000021081950269049879460000009600749849809784510_">
                    <use
                      xlink:href="#SVGID_00000024698157503546209570000003709317895558988948_"
                      style="overflow: visible"
                    />
                  </clipPath>
                  <g
                    style="
                      clip-path: url(#SVGID_00000021081950269049879460000009600749849809784510_);
                    "
                  >
                    <polygon class="st7" points="0.1,0 3.9,0 3.9,3.6 0.1,3.6 							" />
                  </g>
                </g>
              </g>
              <g transform="translate(54.774108351683026 75.77410835168257) scale(1.0 -1.0)">
                <g>
                  <defs>
                    <polygon
                      id="SVGID_00000047772672548767478590000006673946820806159001_"
                      points="2,0 2.6,1.2 3.9,1.4 3,2.3 3.2,3.6 2,3 
								0.8,3.6 1,2.3 0.1,1.4 1.4,1.2 							"
                    />
                  </defs>
                  <clipPath id="SVGID_00000034772847504962586480000013304387627830927527_">
                    <use
                      xlink:href="#SVGID_00000047772672548767478590000006673946820806159001_"
                      style="overflow: visible"
                    />
                  </clipPath>
                  <g
                    style="
                      clip-path: url(#SVGID_00000034772847504962586480000013304387627830927527_);
                    "
                  >
                    <polygon class="st7" points="0.1,0 3.9,0 3.9,3.6 0.1,3.6 							" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div
      class="status-text"
      :style="{
        fontSize: size,
      }"
      >{{ name }}</div
    >
  </div>
</template>
<script lang="ts" setup name="StatusIcon">
  defineProps({
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: '20px',
    },
  })

  const colorType = {
    default: '#B9BDC3', // 已完成
    success: '#A1E2C3', // 已xx 除已完成
    error: '#FFB8B9', // 异常
    warning: '#E9BF99', // 待xx
    grey: '#EBEBEB', // 取消
    blue: 'rgba(22,93,254,0.4)', // 重置
  }
</script>

<style type="text/css" scoped>
  .status-icon-container {
    width: 112px;
    height: 112px;
    display: inline-block;
    position: relative;
    transform: rotate(18deg);
  }
  .status-text {
    /* font-size: 20px; */
    line-height: 112px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 0;
  }
  .st0 {
    clip-path: url(#SVGID_00000157289848331832384770000013777277086671494276_);
  }
  .st1 {
    clip-path: url(#SVGID_00000140693070163654317480000011829056363763926717_);
  }
  .st2 {
    fill: none;
    stroke: currentColor;
    stroke-width: 2.8;
    stroke-miterlimit: 5;
  }
  .st3 {
    clip-path: url(#SVGID_00000116931923903381497840000004455410132263960764_);
  }
  .st4 {
    fill: none;
    stroke: currentColor;
    stroke-width: 1.2;
    stroke-miterlimit: 5;
  }
  .st5 {
    fill: none;
    stroke: currentColor;
    stroke-width: 0.6;
    stroke-miterlimit: 10;
  }
  .st6 {
    clip-path: url(#SVGID_00000119119466512501944230000011886742163737190308_);
  }
  .st7 {
    fill: currentColor;
  }
  .st8 {
    clip-path: url(#SVGID_00000085245408424771118090000016386900742533504958_);
  }
  .st9 {
    clip-path: url(#SVGID_00000079486037021165714580000004799731955169603006_);
  }
  .st10 {
    clip-path: url(#SVGID_00000166670489419426065010000006667098238830151325_);
  }
  .st11 {
    clip-path: url(#SVGID_00000081613032027796667180000011179023187631698852_);
  }
  .st12 {
    clip-path: url(#SVGID_00000165941431745661775970000013242863677605455031_);
  }
</style>
